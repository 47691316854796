import React from 'react'
//import { StaticImage as  Img } from "gatsby-plugin-image";
import Hero from './style'
import { Col, Container, Row } from 'react-bootstrap';
import ReactTypingEffect from 'react-typing-effect';
import { Form, Link } from '~components'
import imageOne from "../../../../assets/image/media/invites.png";
//import { SuperTag } from '~components';
export default function HeroSection(){
  return(
<Hero className="position-relative bg-default">
  <Container>
  <Row>
      <Col className="col-xl-5 col-lg-5 col-md-8 col-xs-11 order-2 order-lg-1"
              xs="12">
        <Hero.ContentBlock mb="35px">
        <Hero.Title as="h1">
        Send up to<br className="d-none d-xs-block" />100 invites/day<br className="d-none d-xs-block" />
        </Hero.Title>          
        
                <Hero.Text>
                Affordable LinkedIn Automation.
                 
                </Hero.Text>
        </Hero.ContentBlock>
        {/* Newsletter */}
        <Hero.NewsletterBox>
          <Hero.Newsletter>
            <form action="//account.we-connect.io/signup"  target="_blank" form-id="kt_login_signup_form" novalidate>
              <Hero.InputBox>
                <input placeholder="@ Enter your email" type="email" name="email" required ps-email-field-id="eab3eabe-8d1d-4025-8361-8694d9c7b13b" className="form-control"/>
                <Hero.Button  className="btn btn-primary text-white" type="submit" ps-submit-button-id="182620e1-2a48-45ce-b154-8b4cc46913a1">Start 14 day free trial</Hero.Button>
                
              </Hero.InputBox>
            </form>
            
          </Hero.Newsletter>
          <Hero.NewsletterText ml="18px" mt="18px">
          No credit card required.
          </Hero.NewsletterText>
          

        </Hero.NewsletterBox>
        {/*/ .Newsletter */}
      </Col>
      <Col xs="14" className="col-xl-7 col-lg-7 col-md-8 col-xs-11 order-1 order-lg-2">
        <Hero.Image>
        <img className="w-100" src={imageOne} alt="content" layout="fullWidth" placeholder="blurred" />
        </Hero.Image>
      </Col>
    </Row>
  </Container>
  
</Hero>
)
}